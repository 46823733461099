import React from 'react';
import { Toolbar, AppBar, Typography, Container, Button,MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useReactRouter from 'use-react-router';

import Menu from '@material-ui/core/Menu';


const AppMenu = (props) => {
  // console.log('hello', props)
  const history = useHistory();
  const { match } = useReactRouter();

  function goHome() {
    history.push(`/ups-online-admission/${match.params.id}`);
  }
  function viewView() {
    history.push(`/ups-online-admission-view/${match.params.id}`);
  }   
  // function editView() {
  //   history.push(`/online-admission-edit/${match.params.id}`);
  // }  
  function viewPayment() {
    history.push(`/ups-online-admission-payment/${match.params.id}`);
  }


  function downloadForm() {
    history.push(`/ups-online-admission-download/${match.params.id}`);
  }


  function downloadForm() {
    history.push(`/ups-online-admission-download/${match.params.id}`);
  }


  return (
    <>
      <div style={{ display: "flex", marginBottom: "20px", marginTop: "20px", justifyContent: "center" }}>
        <Button variant="contained" color="primary" style={{ marginRight: "10px",backgroundColor:"#f68b1f" }} onClick={() => goHome()}> Home</Button>
        {/* <Button variant="contained" color="primary" style={{ marginRight: "10px",backgroundColor:"#f68b1f" }} onClick={() => viewView()}> View</Button> */}
        {/* <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={() => editView()}> Edit</Button> */}


        <Button variant="contained" color="primary" style={{ marginRight: "10px",backgroundColor:"#f68b1f" }} onClick={() => viewPayment()}> Payment</Button>
        <Button variant="contained" color="primary" style={{ marginRight: "10px",backgroundColor:"#f68b1f" }} onClick={() => downloadForm()}> Download</Button>

      <Button variant="contained" color="primary" style={{ marginRight: "10px",backgroundColor:"#f68b1f" }} onClick={() => window.open('https://drive.google.com/drive/u/6/folders/1NyqfY1-K7VYUgW8dgyiK3ttywNoncLYO', '_blank')}>Documents</Button>
         
         
      </div>


    </>
  );
};

export default AppMenu;