import React from 'react';
import { Toolbar, AppBar, Typography, Container, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import background from './background.png'

const Headeruniveristy = (props) => {
  //  console.log('hello',props)
  const history = useHistory();
  const { match } = useReactRouter();



  return (
    <>
      <AppBar position="static" style={{ backgroundImage:"url(" + background + ")", borderBottom: "5px solid #037651", backgroundRepeat:"no-repeat", backgroundSize:"cover" }}>
        <div style={{ padding: "20px 15px" }}>
          <div style={{ textAlign: 'center' }}>
            <img src={props.details?.image} alt="Institute Image" style={{ height: 90, width: 90, borderRadius: 45 }} />
          </div>
          <Typography variant="h5" style={{ textAlign: 'center', marginBottom: -5, fontSize: "2rem" }} >
            {props.details?.instituteName}
          </Typography>

          <Typography style={{ textAlign: 'center', fontSize: "1.25rem" }} >
            {props.details?.address}
          </Typography>
        </div>
      </AppBar>
    </>
  );
};

export default Headeruniveristy;