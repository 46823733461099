import React, { useEffect, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import useReactRouter from 'use-react-router';
import { Button as Buttonh } from '@material-ui/core';
import { green } from "@material-ui/core/colors";
import { TextField } from '@material-ui/core';
import HeaderMenu from '../Components/Header/headeradmission';
import AdmissionMenu from '../Components/Header/upsadmissionmenu';
import Viewfield from '../Components/FormsUI/Viewfield';
import { Button as Submit } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  root: {
    color: green[900],
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)" // default
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C4C4C4" // customized
      }
    }
  }
}));



const DownloadFormUpsAdmission = () => {

  const { match } = useReactRouter();

  const [instInfo, setinstInfo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      let res = await fetch(`${process.env.REACT_APP_API_ROOT}/public/online-applicantion/institute-info/${match.params.id}`)
      let data = await res.json()
      setinstInfo(data?.item)
    }
    fetchData();
  }, []);

  const [textid, settextid] = useState();
  const [payId, setpayId] = useState();
  const history = useHistory();

  const [data, setdata] = useState(null);


  async function formSubmit(e) {
    e.preventDefault();
    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_API_ROOT}/public/online/admission/single/applicant/info?instituteId=${match.params.id}&registrationId=${textid}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        // body: JSON.stringify(submit)
      });
      if (rawResponse.status === 201 || rawResponse.status === 200) {
        const content = await rawResponse.json();
        if (content?.messageType === 1) {
          setdata(content?.item)
        } else {
          alert("No Data Found");
          setdata(null);
        }

      } else {
        alert("Something went wrong");

      }

    }
    catch (err) {
      // not jumping in here.
      console.log(err)
    }
  }


  // https://api.shebashikkha.com/public/online/admission/confirmation/letter/download?instituteId=1&registrationId=2400001


  function downloadPdf() {
    window.open(`${process.env.REACT_APP_API_ROOT}/public/online/admission/ups-confirmation/letter/download?instituteId=${parseInt(match.params.id)}&registrationId=${textid}`);
  }

  function downloadAdmitCard() {

    let paymentStatus = data?.paymentStatus;

    if(paymentStatus==='Unpaid'){

      alert("Please Pay your application fee to download Admit Card.")

    }else{
      window.open(`${process.env.REACT_APP_API_ROOT}/public/online/short/admission/admit/card/download?instituteId=${parseInt(match.params.id)}&registrationId=${textid}`);
    }

  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderMenu details={{ instituteName: instInfo?.institutename, address: instInfo?.instituteaddress, image: instInfo?.imageLink }} />
      </Grid>

      <Grid item xs={12} style={{ background: "#fff", boxShadow: "rgb(216 216 216) 0px 0px 15px 0px", padding: "15px" }}>

        <Divider />
        <AdmissionMenu />
        <br />
        <Grid item xs={12}>
          <Typography style={{ fontSize: "24px", textAlign: "center", marginBottom: "10px", color: "#f68b1f", fontWeight: "500" }}>
            Download
          </Typography>
        </Grid>
        <br />
        <Divider />
        <br />
        <Grid item xs={12}>
          <Container maxWidth="md">
            <form onSubmit={formSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField id="outlined-basic" label="Applicant Id" variant="outlined" fullWidth required type="number" onChange={(e) => settextid(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Buttonh type="submit" color="primary" variant="contained" fullWidth style={{ height: 55,backgroundColor:"#f68b1f" }}>Search</Buttonh>
                </Grid>
              </Grid>
            </form>
            <br></br><br />

            
            {data !== null &&


<Grid container spacing={2}>
                
                <Grid item>
                <img src={data?.studentImageLink} alt="Student Photo" style={{ height: 90, width: 90, borderRadius: 45 }} />

          </Grid>

          <Grid item xs>
                  <Viewfield
                    label="Applicant Name"
                    name={data?.applicantName}
                  />

                  <Viewfield
                    label="Applied Class"
                    name={data?.className}
                  />

                <Viewfield
                    label="Father's Name"
                    name={data?.fatherName}
                  />

            <Viewfield
                    label="Date of Birth"
                    name={data?.dateOfBirth}
                  />

                  
              </Grid>

              <Grid item xs>
                  <Viewfield
                    label="Gender"
                    name={data?.gender}
                  />

                <Viewfield
                    label="Version"
                    name={data?.medium}
                  />

                <Viewfield
                    label="Mother's Name"
                    name={data?.motherName}
                  />

              <Submit  color="primary" variant="contained" onClick={() => downloadAdmitCard()} >
                    Download Admit Card
              </Submit>
              </Grid>

              <Grid item xs>
                  <Viewfield
                    label="Religion"
                    name={data?.religion}
                  />

                <Viewfield
                    label="Payment Status"
                    name={data?.paymentStatus}
                  />

                <Viewfield
                    label="Quota"
                    name={data?.quota}
                  />

                <Submit color="primary" variant="contained" onClick={() => downloadPdf()} >
                   Download Form
                  </Submit>
                  
              </Grid>

              </Grid>
            }
          </Container>
        </Grid>

      </Grid>
    </Grid>
  );
};

export default DownloadFormUpsAdmission;
