import React from 'react';
import {
  Typography
} from '@material-ui/core';

const Viewfield = ({
  label,
  name,
}) => {

  return (
    <>
    <Typography style={{fontWeight:"bold"}}>
    {label}
  </Typography>    
  <Typography >
    {name}
  </Typography>
  </>
  );
};

export default Viewfield;
