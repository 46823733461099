import React, { useEffect, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import useReactRouter from 'use-react-router';
import { Button as Buttonh } from '@material-ui/core';
import { green } from "@material-ui/core/colors";
import { TextField } from '@material-ui/core';
import HeaderMenu from '../Components/Header/headeradmission';
import AdmissionMenu from '../Components/Header/largeadmissionmenu';
import Viewfield from '../Components/FormsUI/Viewfield';
import { Button as Submit } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  root: {
    color: green[900],
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)" // default
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C4C4C4" // customized
      }
    }
  }
}));



const ViewFormLargeAdmission = () => {

  const { match } = useReactRouter();

  const [instInfo, setinstInfo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      let res = await fetch(`https://api.shebashikkha.com/public/online-applicantion/institute-info/${match.params.id}`)
      let data = await res.json()
      setinstInfo(data?.item)
    }
    fetchData();
  }, []);

  const [textid, settextid] = useState();
  const [payId, setpayId] = useState();
  const history = useHistory();

  const [data, setdata] = useState(null);


  function downloadPdf() {
    window.open(`${process.env.REACT_APP_API_ROOT}/public/online/admission/confirmation/letter/download?instituteId=${parseInt(match.params.id)}&registrationId=${textid}`);
  }

  async function formSubmit(e) {
    e.preventDefault();
    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_API_ROOT}/public/online/admission/single/applicant/info?instituteId=${match.params.id}&registrationId=${textid}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        // body: JSON.stringify(submit)
      });
      if (rawResponse.status === 201 || rawResponse.status === 200) {
        const content = await rawResponse.json();
        if (content?.messageType === 1) {
          setdata(content?.item)
        } else {
          alert("No Data Found");
          setdata(null);
        }

      } else {
        alert("Something went wrong");

      }

    }
    catch (err) {
      // not jumping in here.
      console.log(err)
    }
  }



  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderMenu details={{ instituteName: instInfo?.institutename, address: instInfo?.instituteaddress, image: instInfo?.imageLink }} />
      </Grid>

      <Grid item xs={12} style={{ background: "#fff", boxShadow: "rgb(216 216 216) 0px 0px 15px 0px", padding: "15px" }}>

        <Divider />
        <AdmissionMenu />
        <br />

        <Divider />
        <br />
        <Grid item xs={12}>
          <Container maxWidth="md">
            <form onSubmit={formSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField id="outlined-basic" label="Applicant Id" variant="outlined" fullWidth required type="number" onChange={(e) => settextid(e.target.value)} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Buttonh type="submit" color="primary" variant="contained" fullWidth style={{ height: 55,backgroundColor:"#f68b1f" }}> Search</Buttonh>
                </Grid>
                
                {/* <Grid item xs={12} sm={3}>

                <Submit color="primary" variant="contained" onClick={() => downloadPdf()} style={{ height: 55 }}>
                   Download Form
                  </Submit>
                </Grid> */}
              </Grid>
            </form>
            <br></br><br />


            {data !== null &&
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
            <Typography>Student Photo</Typography>
                  <img src={data?.studentImageLink} alt="Student Photo" style={{ height: 90, width: 90, borderRadius: 45 }} />
                </Grid>

                {/* <Grid item xs={12} sm={4}>
                <Typography>Father's Photo</Typography>
                  <img src={data?.fatherImageLink} alt="Institute Image" style={{ height: 90, width: 90, borderRadius: 45 }} />
                </Grid>


                <Grid item xs={12} sm={4}>
                <Typography>Mother's Photo</Typography>
                  <img  src={data?.motherImageLink} alt="Institute Image" style={{ height: 90, width: 90, borderRadius: 45 }} />
                </Grid> */}


                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Applicant Name"
                    name={data?.applicantName}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Gender"
                    name={data?.gender}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Religion"
                    name={data?.religion}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Blood Group"
                    name={data?.bloodGroup}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Date of Birth"
                    name={data?.dateOfBirth}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Nationality"
                    name={data?.nationality}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Applied Class"
                    name={data?.className}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Applied Class"
                    name={data?.className}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Father Name"
                    name={data?.fatherName}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Father Mobile"
                    name={data?.fatherMobile}
                  />
                </Grid>
               
                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Father NID"
                    name={data?.fatherNid}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Father Education"
                    name={data?.fatherEducation}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Father Occupation"
                    name={data?.fatherOccupation}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Father Income"
                    name={data?.fatherIncome}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Mother Name"
                    name={data?.motherName}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Mother Mobile"
                    name={data?.motherMobile}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Mother NID"
                    name={data?.motherNid}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Mother Education"
                    name={data?.motherEducation}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Mother Occupation"
                    name={data?.motherOccupation}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Mother Income"
                    name={data?.motherIncome}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Present Address"
                    name={data?.presentAddress}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Permanent Address"
                    name={data?.permanentAddress}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Guardian Name"
                    name={data?.localGuardianName}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Guardian Mobile"
                    name={data?.localGuardianMobile}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Guardian Email"
                    name={data?.localGuardianEmail}
                  />
                </Grid>
                
                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Quota"
                    name={data?.quota}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Viewfield
                    label="Previous Institute"
                    name={data?.lastInstituteName}
                  />
                </Grid>

              </Grid>
            }
          </Container>

        </Grid>

      </Grid>
    </Grid>
  );
};

export default ViewFormLargeAdmission;
