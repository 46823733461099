import React, { useEffect, useState } from 'react';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import useReactRouter from 'use-react-router';
import { Button as Buttonh } from '@material-ui/core';
import { green } from "@material-ui/core/colors";
import { TextField } from '@material-ui/core';
import HeaderMenu from '../Components/Header/headeradmission';
import Viewfield from '../Components/FormsUI/Viewfield';
const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  root: {
    color: green[900],
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)" // default
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C4C4C4" // customized
      }
    }
  }
}));



const AdmissionLottery = () => {

  const { match } = useReactRouter();

  const [instInfo, setinstInfo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      let res = await fetch(`https://api.shebashikkha.com/public/online-applicantion/institute-info/${match.params.id}`)
      let data = await res.json()
      setinstInfo(data?.item)
    }
    fetchData();
  }, []);

  const [textid, settextid] = useState();
  const [payId, setpayId] = useState();
  const history = useHistory();

  const [data, setdata] = useState(null);


  async function formSubmit(e) {
    e.preventDefault();
    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_API_ROOT}/public/online/admission/single/applicant/info?instituteId=${match.params.id}&registrationId=${textid}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        // body: JSON.stringify(submit)
      });
      if (rawResponse.status === 201 || rawResponse.status === 200) {
        const content = await rawResponse.json();
        if (content?.messageType === 1) {
          setdata(content?.item)
        } else {
          alert("No Data Found");
          setdata(null);
        }

      } else {
        alert("Something went wrong");

      }

    }
    catch (err) {
      // not jumping in here.
      console.log(err)
    }
  }


  // https://api.shebashikkha.com/public/online/admission/confirmation/letter/download?instituteId=1&registrationId=2400001


  function downloadPdf() {
    window.open(`${process.env.REACT_APP_API_ROOT}/public/online/admission/confirmation/letter/download?instituteId=${parseInt(match.params.id)}&registrationId=${textid}`);
  }

  function downloadAdmitCard() {

    let paymentStatus = data?.paymentStatus;

    if(paymentStatus==='Unpaid'){
      console.log('------------------',paymentStatus);

      alert("Please Pay your application fee to download Admit Card.")

    }else{
      window.open(`${process.env.REACT_APP_API_ROOT}/public/online/admission/admit/card/download?instituteId=${parseInt(match.params.id)}&registrationId=${textid}`);
    }

  }

  function viewForm() {
    history.push("/online-applicant-info/" + parseInt(match.params.id));
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderMenu details={{ instituteName: instInfo?.institutename, address: instInfo?.instituteaddress, image: instInfo?.imageLink }} />
      </Grid>

      <Grid item xs={12} style={{ background: "#fff", boxShadow: "rgb(216 216 216) 0px 0px 15px 0px", padding: "15px" }}>

        <Divider />
        {/* <AdmissionMenu /> */}
        <br />
        <Grid item xs={12}>
          {/* <Typography style={{ fontSize: "24px", textAlign: "center", marginBottom: "10px", color: "#009688", fontWeight: "500" }}>
            Payment
          </Typography> */}
        </Grid>
        <br />
        <Divider />
        <br />
        <Grid item xs={12}>
          <Container maxWidth="md">
        
            {data !== null &&

          <Grid container spacing={2}>
                
                <Grid item>
                <img src={data?.studentImageLink} alt="Institute Image" style={{ height: 500, width: 500, borderRadius: 45 }} />

          </Grid>





          <Grid item xs>

            
                  <Viewfield
                    label="Student Name"
                    name={data?.applicantName}
                  />

                  <Viewfield  style={{ fontFamily: "Spicy Rice", color: "pink" }}
                    label="Father's Name"
                    name={data?.fatherName}
                  />

                  
                <Viewfield
                    label="Mother's Name"
                    name={data?.motherName}
                  />


                  <Viewfield
                    label="Applied Class"
                    name={data?.className}
                  />

                
                  
              <Grid item xs>
                  <Viewfield
                    label="Gender"
                    name={data?.gender}
                  />
              </Grid>


              


            {/* <Submit  color="primary" variant="contained" onClick={() => downloadAdmitCard()} >
                   Download Admit Card
                  </Submit> */}
              </Grid>


                {/* <Submit color="primary" variant="contained" onClick={() => downloadPdf()} >
                   Download Form
                  </Submit> */}
             
              </Grid>
            }
          </Container>
        </Grid>

      </Grid>

      <form onSubmit={formSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField id="outlined-basic" label="Applicant Id" variant="outlined" fullWidth required type="number" onChange={(e) => settextid(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Buttonh type="submit" color="primary" variant="contained" fullWidth style={{ height: 55, marginBottom:10 }}> Search</Buttonh>
                </Grid>
              </Grid>
            </form>

      
    </Grid>
  );
};

export default AdmissionLottery;
