import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import { Formik, Form, yupToFormErrors } from "formik";
import {
  Checkbox,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Step,
  StepButton,
  StepIcon,
  StepLabel,
  Stepper,
  TextField as Upload,
} from "@material-ui/core";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import Textfield from "../../Components/FormsUI/Textfield";
import Select from "../../Components/FormsUI/Select";
import DateTimePicker from "../../Components/FormsUI/DataTimePicker";
import {
  fatheroccupationdata,
  motheroccupationdata,
  autismData,
  bloodData,
  genederData,
  maritalData,
  religionData,
  examNameData2,
  gradeData,
  boardData2,
  examGroupData,
} from "../../data/data";
import { Link, useHistory } from "react-router-dom";
import Input from "@material-ui/core/TextField";
import useReactRouter from "use-react-router";
import { Button as Submit } from "@material-ui/core";
import { Button as Buttonh } from "@material-ui/core";
import DropDown from "@material-ui/core/Select";
import { green, purple } from "@material-ui/core/colors";
import { v4 as uuidv4 } from "uuid";
import { TextField as TextF, MenuItem } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveIcon from '@material-ui/icons/Save';
import AppMenuUniversity from "../../Components/Header/appmenuuniveristy";
import Headeruniveristy from "../../Components/Header/headeruniveristy";

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(8),
  },
  root: {
    color: green[900],
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)", // default
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C4C4C4", // customized
      },
    },
  },
}));

const UniversityApplicationForm = () => {
  const info = JSON.parse(sessionStorage.getItem("univeristyinfo"));
  const subjectinfo = JSON.parse(sessionStorage.getItem("univeristysubject"));

  const [majorSubject, setmajorSubject] = useState('');

  const history = useHistory();

  if (info === null) {
    history.push("/");
  }

  const INITIAL_FORM_STATE = {
    studentName: info?.studentName,
    fatherName: info?.fatherName,
    motherName: info?.motherName,
    motherMobile: info?.motherMobile,
    fatherMobile: info?.fatherMobile,
    guardianMobile: info?.guardianMobile,
    dateOfBirth: info?.dob,
    religion: info?.religion,
    gender: info?.gender,
    studentMobile: info?.studentMobile,
    bloodGroup: info?.bloodGroup,
    maritalStatus: info?.maritalStatus,
    nidBc: info?.nidBc,
    nationality: info?.nationality,
    fatherAnnualIncome: info?.fatherAnnualIncome,
    fatherNid: info?.fatherNid,
    motherNid: info?.motherNid,
    motherAnnualIncome: info?.motherAnnualIncome,
    presentVillege: info?.presentVillege,
    presentPostOffice: info?.presentPostOffice,
    permanentVillege: info?.permanentVillege,
    permanentPostOffice: info?.permanentPostOffice,
    guardianName: info?.guardianName,
    guardianEmail: info?.guardianEmail,
    departmentName: info?.departmentName,
    sessionName: info?.sessionName,
    classRoll: info?.classRoll,
    presentDistrict: info?.presentDistrictId,
    presentThanaId: info?.presentThanaId,
    permanentDistrict: info?.permanentDistrictId,
    permanentThanaId: info?.permanentThanaId,
  };

  const FORM_VALIDATION = Yup.object().shape({
    studentName: Yup.string().required("Required"),
    fatherName: Yup.string().required("Required"),
    motherName: Yup.string().required("Required"),
    fatherMobile: Yup.string().required("Required"),
    motherMobile: Yup.string().required("Required"),
    guardianMobile: Yup.string().required("Required"),
    religion: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    bloodGroup: Yup.string().required("Required").nullable(),
    dateOfBirth: Yup.string().required("Required").nullable(),
    maritalStatus: Yup.string().required("Required").nullable(),
    nidBc: Yup.string().nullable().required("Required"),
    nationality: Yup.string().nullable().required("Required"),
    fatherAnnualIncome: Yup.string().required("Required"),
    motherNid: Yup.string().required("Required"),
    fatherNid: Yup.string().required("Required"),
    motherAnnualIncome: Yup.string().required("Required"),
    guardianName: Yup.string().required("Required"),
    /**********************/
    guardianEmail: Yup.string(),
    presentVillege: Yup.string(),
    presentPostOffice: Yup.string().required("Required"),

    permanentPostOffice: Yup.string().required("Required"),
    permanentVillege: Yup.string(),

    // presentDistrict: Yup.string()
    //   .required('Required'),
    // presentThanaId: Yup.string()
    //   .required('Required'),
    // permanentDistrict: Yup.string()
    //   .required('Required'),
    // permanentThanaId: Yup.string()
    //   .required('Required'),
  });

  const classes = useStyles();
  const { match } = useReactRouter();

  const [payId, setpayId] = useState();
  const [successPay, setsuccessPay] = useState(false);
  const [step, setStep] = useState(1);

  const [imageFileContent, setimageFileContent] = useState();
  const [imageFileSave, setimageFileSave] = useState(!info?.imageStatus);
  const [imageFileName, setimageFileName] = useState(undefined);



  function clearFileInput(ctrl) {
    try {
      ctrl.value = null;
    } catch (ex) { }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }
  const imageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file?.size > 600000) {
      // file.target.value = null;
      clearFileInput(document.getElementById("imgfile"));
      setimageFileSave(false);
      setimageFileContent("");
      setimageFileName(undefined);
      alert("Image size should be less than 600KB");
      return;
    }
    if (reader !== undefined && file !== undefined) {
      setimageFileSave(true);

      reader.onloadend = () => {
        // setFile(file)
        // setSize(file.size);
        // setName(file.name)
        // setImagePreview(reader.result)
        setimageFileName(file.name);
        setimageFileContent(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(file);
    }
  };

  const validateOthers = () => {
    // console.log('sss')
  };

  const clearothers = () => {
    document.getElementById("imgfile").value = null;
  };

  const [districtList, setdistrictList] = useState([]);
  const [thanaList, setPresentthanaList] = useState([]);
  const [permanentThanaList, setPermanentThanaList] = useState([]);
  const [presentThanaId, setPresentThanaId] = useState("");
  const [permanentThanaId, setPermanentThanaId] = useState("");
  const [presentDisId, setPresentDisId] = useState("");
  const [permanentDisId, setPermanentDISId] = useState("");
  useEffect(() => {
    async function fetchMyAPI() {
      if (info===null){
        return
      }
      let disReponse = await fetch(
        "https://api.shebashikkha.com/location/info/district-list"
      );
      disReponse = await disReponse.json();
      //  console.log(disReponse)
      setdistrictList(disReponse?.item);
      setTimeout(() => {
        if (info?.permanentDistrictId!==null && info?.permanentDistrictId!==''){
          setPermanentDISId(info?.permanentDistrictId);
          fetchThanaPerm();
        }
        if (info?.presentDistrictId!==null && info?.presentDistrictId!=='') {
          setPresentDisId(info?.presentDistrictId);
          fetchThanaPermPresent();
        }
      }, 1000);
    }

    async function fetchThanaPerm() {
      if (info?.permanentDistrictId!==null && info?.permanentDistrictId!=='') {
        let thanaReponse = await fetch(
          `https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${info.permanentDistrictId}`
        );
        thanaReponse = await thanaReponse.json();
        setPermanentThanaList(thanaReponse?.item);
        setTimeout(() => {
          if (info?.permanentThanaId!==null && info?.permanentThanaId!==''){
            setPermanentThanaId(info?.permanentThanaId);
          }
        }, 1000);
      }
    }    
    async function fetchThanaPermPresent() {
      if (info?.presentDistrictId!==null && info?.presentDistrictId!=='') {
        let thanaReponse = await fetch(
          `https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${info.presentDistrictId}`
        );
        thanaReponse = await thanaReponse.json();
        setPresentthanaList(thanaReponse?.item);
        setTimeout(() => {
          if (info?.presentThanaId!==null || info?.presentThanaId!==''){
            setPresentThanaId(info?.presentThanaId);
          }
        }, 1000);
      }
    }
    fetchMyAPI();
  }, []);

  async function handlePresentDistrict(evt) {
    setPresentDisId(evt.target.value);
    let thanaReponse = await fetch(
      `https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${evt.target.value}`
    );
    thanaReponse = await thanaReponse.json();
    setPresentthanaList(thanaReponse?.item);
  }

  async function handlePresentThana(evt) {
    setPresentThanaId(evt.target.value);
    // console.log(evt.target.value);
  }

  async function permanenThandleThana(evt) {
    setPermanentThanaId(evt.target.value);
    // console.log(evt.target.value)
  }

  async function handlePermanentDistrict(evt) {
    setPermanentDISId(evt.target.value);
    let thanaReponse = await fetch(
      `https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${evt.target.value}`
    );
    thanaReponse = await thanaReponse.json();
    setPermanentThanaList(thanaReponse?.item);
  }
  async function handlePermanentDistrict2(evt, thana) {
    setPermanentDISId(evt);
    let thanaReponse = await fetch(
      `https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${evt}`
    );
    thanaReponse = await thanaReponse.json();
    setPermanentThanaList(thanaReponse?.item);
    if (thanaReponse?.item.length > 0) {
      setPermanentThanaId(thana);
    }
  }

  const [paymentStatus, setpaymentStatus] = useState(false);
  function paymentPage() {
    var win = window.open(
      "https://shebashikkha.com/public/online/application-fee/pay?registrationId=" +
      payId
    );
    var timer = setInterval(function () {
      if (win.closed) {
        clearInterval(timer);
        fetch(
          "https://shebashikkha.com/public/single/applicant/info?registrationId=" +
          payId +
          "&instituteId=" +
          parseInt(match.params.id)
        )
          .then((response) => response.json())
          .then((data) => {
            if (data?.item?.paymentStatus === "Paid") {
              setpaymentStatus(true);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, 3000);
  }

  function downloadPdf() {
    window.open(
      "https://shebashikkha.com/public/confirmation/letter/download?registrationId=" +
      payId +
      "&inatituteId=" +
      parseInt(match.params.id)
    );
  }

  function viewPayment() {
    history.push("/online-applicant-fee-payment/" + parseInt(match.params.id));
  }

  const [viewData, setviewData] = useState();
  const [submit, setsubmit] = useState({});

  // console.log('info', info);

  async function nextData(values) {
    if (info?.imageStatus === false && imageFileContent === undefined) {
      alert("Please upload a photo");
      return;
    }
    // if (majorSubject === '') {
    //   alert("Please select major subject");
    //   return;
    // }
    if (educationInfos?.[0]?.examName === 'x') {
      alert("Please input exam information");
      return;
    }
    let postData = {
      // academicYear: info?.academicYear,
      bloodGroup: values.bloodGroup,
      studentMobile: values.studentMobile,
      choiceSubjects: '',
      educationInfos: educationInfos.map(item => {
        return {
          examName: item?.examName,
          board: item?.board,
          gpa: item?.gpa,
          roll: item?.roll,
          year: item?.year,
        }
      }),
      majorSubject: majorSubject,
      fatherName: values.fatherName,
      fatherMobile: values.fatherMobile,
      motherMobile: values.motherMobile,
      studentImage:
        imageFileContent === undefined
          ? "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          : imageFileContent,
      imageChange: imageFileSave,
      imageFullName: imageFileName,
      guardianEmail: values.guardianMobile,
      instituteId: info?.instituteId,
      studentId: info?.studentId,
      motherName: values.motherName,
      dob: values.dateOfBirth,
      gender: values.gender,
      studentName: values.studentName,
      religion: values.religion,
      maritalStatus: values.maritalStatus,
      nidBc: values.nidBc,
      nationality: values.nationality,
      fatherAnnualIncome: values.fatherAnnualIncome,
      motherNid: values.motherNid,
      fatherNid: values.fatherNid,
      motherAnnualIncome: values.motherAnnualIncome,
      guardianName: values.guardianName,
      guardianMobile: values.guardianMobile,
      guardianAnnualIncome: 0,
      /****Address objects***/
      presentPostOffice: values.presentPostOffice,
      presentThanaId: presentThanaId,
      presentVillege: values.presentVillege,
      presentAddress: values.presentVillege,
      permanentAddress: values.permanentVillege,
      permanentVillege: values.permanentVillege,
      permanentPostOffice: values.permanentPostOffice,
      permanentThanaId: permanentThanaId,
      // other
    };

    universitySubmit(postData);
    // setStep(2);
  }

  async function universitySubmit(data) {
    try {
      const rawResponse = await fetch(
        `${process.env.REACT_APP_API_ROOT_UNIVERSITY}/public/student/form-fillup/update`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (rawResponse.status === 201 || rawResponse.status === 200) {
        const content = await rawResponse.json();
        //   console.log(content);

        if (content.messageType === 1) {
          setsuccessPay(true);
          setStep(2);
        }
        if (content.messageType === 0) {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");

      }
    } catch (err) {
      // not jumping in here.
      console.log(err);
    }
  }



  const [sameAs, setSameAs] = useState(false);

  const [educationInfos, seteducationInfos] = useState([
    {
      key: uuidv4(),
      board: "x",
      examName: "x",
      gpa: "",
      roll: "",
      year: "",
    },
  ]);

  useEffect(()=>{
    if (info?.educationInfos?.length===0) return
    if (info?.educationInfos?.length>0){
      seteducationInfos(info?.educationInfos);
    }
  },[])

  function addItem() {
    let tem = {
      key: uuidv4(),
      board: "x",
      examName: "x",
      gpa: "",
      roll: "",
      year: "",
    };
    const newData = [...educationInfos, tem];
    seteducationInfos(newData);
  }

  function removeItem(key) {
    let temp = educationInfos.filter((item) => item?.key !== key);
    seteducationInfos(temp);
  }

  function addText(e, index, key) {
    const newData = [...educationInfos];
    newData[index][key] = e.target.value;
    seteducationInfos(newData);
  }


  return (
    <Grid container>
      <Grid item xs={12}>
        <Headeruniveristy
          details={{
            instituteName: info?.instituteName,
            address: info?.instituteAddress,
            image: info?.instituteLogoLink,
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          background: "#fff",
          boxShadow: "rgb(216 216 216) 0px 0px 15px 0px",
          padding: "15px",
        }}
      >
        <AppMenuUniversity
          details={{
            customStudentId: info?.customStudentId,
            instituteId: info?.instituteId,
            downloadFlag: info?.downloadFlag,
          }}
        />
        <Divider />
        <Stepper linear={true} activeStep={step - 1}>
          <Step>
            <StepLabel>Student Basic Information</StepLabel>
          </Step>
          {/* <Step>
            <StepLabel>Subject Information</StepLabel>
          </Step> */}
          <Step>
            <StepLabel>Final Result</StepLabel>
          </Step>
        </Stepper>
        <Divider />
        {!successPay && step === 1 && (
          <div className={classes.formWrapper}>
            <Formik
              initialValues={{
                ...INITIAL_FORM_STATE,
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                nextData(values);
              }}
            >
              {(formikProps) => {
                const { values, handleChange } = formikProps;
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          style={{
                            fontSize: "24px",
                            textAlign: "center",
                            marginBottom: "10px",
                            color: "#009688",
                            fontWeight: "500",
                          }}
                        >
                          Student Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 0 }}>
                        <Typography style={{ fontWeight: "bold" }}>Academic Information</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="classRoll"
                          label="Class Roll"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="sessionName"
                          label="Session"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="departmentName"
                          label="Department/Subject"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography style={{ fontWeight: "bold" }}>Basic Information</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Textfield name="studentName" label="Applicant Name" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Select
                          name="gender"
                          label="Gender"
                          options={genederData}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="studentMobile"
                          label="Student Mobile"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Select
                          name="religion"
                          label="Religion"
                          options={religionData}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <DateTimePicker
                          name="dateOfBirth"
                          label="Date Of Birth"
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="bloodGroup"
                          label="Blood Group"
                          options={bloodData}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="maritalStatus"
                          label="Marital Status"
                          options={maritalData}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield name="nidBc" label="Birth Certificate/NID" />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield name="nationality" label="Nationality" />
                      </Grid>

                      {/* <Grid item xs={12} sm={4}></Grid> */}
                      {/* {info?.studentImageLink !== null && (
                        <Grid item xs={12} sm={2}>
                          <img
                            src={info?.studentImageLink}
                            alt="Student Image"
                            height={100}
                            width={100}
                          />
                        </Grid>
                      )} */}
                      <Grid item xs={12} sm={4}>
                        <Upload
                          required={!info?.imageStatus}
                          id="imgfile"
                          style={{ width: "100%" }}
                          classes={{ root: classes.root }}
                          variant="outlined"
                          label="Student Photo"
                          focused={imageFileSave == "" && true}
                          name="file"
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            imageUpload(e);
                          }}
                        />
                        {/* <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" style={{ display: imageFileSave === '' ? 'none' : imageFileSave === true ? 'none' : 'inherit' }}>Required</p> */}
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography style={{ fontWeight: "bold" }}>Father's Information</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Textfield name="fatherName" label="Father's Name" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="fatherMobile"
                          label="Father's Mobile"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield name="fatherNid" label="Father's NID" />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="fatherAnnualIncome"
                          label="Father's Annual Income"
                        />
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography style={{ fontWeight: "bold" }}>Mother's Information</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Textfield name="motherName" label="Mother's Name" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="motherMobile"
                          label="Mother's Mobile"

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield name="motherNid" label="Mother's NID" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="motherAnnualIncome"
                          label="Mother's Annual Income"
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography style={{ fontWeight: "bold" }}>Present Address Information</Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="presentVillege"
                          label="Village"
                        //
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="presentPostOffice"
                          label="Post Office"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        {/* <SelectDistrict
                      name="presentDistrict"
                      label="District"
                      value={districtList}
                      onChange={handlePresentDistrict}
                    /> */}
                        <TextF
                          value={presentDisId}
                          required
                          select={true}
                          variant="outlined"
                          fullWidth
                          label="District"
                          onChange={(e) => {
                            handlePresentDistrict(e);
                          }}
                        >
                          {districtList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.districtId}>
                                {item.districtName}
                              </MenuItem>
                            );
                          })}
                        </TextF>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextF
                          value={presentThanaId}
                          required
                          select={true}
                          variant="outlined"
                          fullWidth
                          label="Thana"
                          onChange={(e) => {
                            handlePresentThana(e);
                          }}
                        >
                          {thanaList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.thanaId}>
                                {item.thanaName}
                              </MenuItem>
                            );
                          })}
                        </TextF>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          marginTop: 15,
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography style={{ fontWeight: "bold" }}>Permanent Address Information</Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            marginLeft: 25,
                          }}
                        >
                          <Checkbox
                            checked={sameAs}
                            disabled={presentThanaId == "" ? true : false}
                            onChange={() => {
                              setSameAs(!sameAs);
                              if (!sameAs === true) {
                                formikProps.setFieldValue(
                                  "permanentVillege",
                                  values.presentVillege
                                );
                                formikProps.setFieldValue(
                                  "permanentPostOffice",
                                  values.presentPostOffice
                                );
                                handlePermanentDistrict2(
                                  presentDisId,
                                  presentThanaId
                                );
                              } else if (!sameAs === false) {
                                formikProps.setFieldValue(
                                  "permanentVillege",
                                  ""
                                );
                                formikProps.setFieldValue(
                                  "permanentPostOffice",
                                  ""
                                );
                                setPermanentDISId("");
                                setPermanentThanaId("");
                              }
                            }}
                          />
                          <Typography>Same as Present Address</Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="permanentVillege"
                          label="Village"
                        //
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="permanentPostOffice"
                          label="Post Office"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextF
                          value={permanentDisId}
                          required
                          select={true}
                          variant="outlined"
                          fullWidth
                          label="District"
                          onChange={handlePermanentDistrict}
                        >
                          {districtList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.districtId}>
                                {item.districtName}
                              </MenuItem>
                            );
                          })}
                        </TextF>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextF
                          value={permanentThanaId}
                          required
                          select={true}
                          variant="outlined"
                          fullWidth
                          label="Thana"
                          onChange={(e) => {
                            permanenThandleThana(e);
                          }}
                        >
                          {permanentThanaList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.thanaId}>
                                {item.thanaName}
                              </MenuItem>
                            );
                          })}
                        </TextF>
                      </Grid>
                      {/***********************************/}

                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography style={{ fontWeight: "bold" }}>Legal Guardian Information</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield name="guardianName" label="Guardian Name" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="guardianMobile"
                          label="Mobile Number"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="guardianEmail"
                          label="Guardian Email"
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography style={{ fontWeight: "bold" }}>Exam Information</Typography>
                      </Grid>
                      {educationInfos.map((item, index) => {
                        return (
                          <>
                            <Grid item xs={12} sm={4}>
                              <DropDown
                                onChange={(e) => addText(e, index, "examName")}
                                variant="outlined"
                                value={item?.examName}
                                style={{ width: "100%" }}
                              >
                                <MenuItem disabled value={"x"}>Select Exam</MenuItem>
                                {examNameData2?.map(item => {
                                  return <MenuItem value={item}>{item}</MenuItem>
                                })}
                              </DropDown>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Input
                                label="Exam Roll"
                                variant="outlined"
                                style={{ width: "100%" }}
                                value={item?.roll}
                                onChange={(e) => addText(e, index, "roll")}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <DropDown
                                onChange={(e) => addText(e, index, "board")}
                                variant="outlined"
                                value={item?.board}
                                style={{ width: "100%" }}
                              >
                                <MenuItem disabled value={"x"}>Select Board</MenuItem>
                                {boardData2?.map(item => {
                                  return <MenuItem value={item}>{item}</MenuItem>
                                })}
                              </DropDown>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Input
                                label="Passing Year"
                                variant="outlined"
                                style={{ width: "100%" }}
                                value={item?.year}
                                onChange={(e) => addText(e, index, "year")}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Input
                                label="GPA"
                                variant="outlined"
                                style={{ width: "100%" }}
                                value={item?.gpa}
                                onChange={(e) => addText(e, index, "gpa")}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignContent: "center",
                                  alignItems: 'center',
                                  marginTop: 10
                                }}
                              >
                                {educationInfos?.length - 1 === index && (
                                  <Buttonh
                                    color="primary"
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                    onClick={() => addItem()}
                                  >
                                    Add
                                  </Buttonh>
                                )}
                                <Buttonh
                                  variant="contained"
                                  disabled={index === 0}
                                  startIcon={<RemoveIcon />}
                                  onClick={() => removeItem(item?.key)}
                                >
                                  Remove
                                </Buttonh>
                              </div>
                            </Grid>
                          </>
                        );
                      })}

                      {/* <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography style={{ fontWeight: "bold" }}>Subject Information</Typography>
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        {/* <DropDown
                          onChange={(e) => setmajorSubject(e.target.value)}
                          variant="outlined"
                          defaultValue="x"
                          style={{ width: "100%" }}
                        >
                          <MenuItem disabled value={"x"}>Major Subject</MenuItem>
                          {subjectinfo?.map(item => {
                            return <MenuItem value={item?.name}>{item?.name}</MenuItem>
                          })}
                        </DropDown> */}
                      </Grid>

                      <Grid item xs={6} style={{ marginBottom: 20 }}>
                        <Submit
                          type="submit"
                          style={{ float: "right" }}
                          color="primary"
                          variant="contained"
                          startIcon={<SaveIcon />}
                          onClick={() => validateOthers()}
                        >
                          Save
                        </Submit>
                        {/* <Submit type="reset" color="secondary" variant="contained" style={{ marginLeft: 5 }} onClick={() => clearothers()}>
                          Reset
                        </Submit> */}
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}

        {successPay && step === 2 && (
          <>
            <div
              className="h-100 w-100 p-5 text-center"
              style={{ marginTop: "2%" }}
            >
              <h2
                className="MuiTypography-root MuiTypography-h5"
                style={{ color: "#009688" }}
              >
                Congratulation! {info?.studentName}{" "}
              </h2>
              <p className="MuiTypography-root MuiTypography-h6">
                You application has been submitted.
              </p>

              {/* <p className='MuiTypography-root MuiTypography-h6'>Applicant ID : {payId}</p> */}
              <p
                className="MuiTypography-root MuiTypography-h6"
                style={{ marginTop: 0, textAlign: "center" }}
              >
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_API_ROOT_UNIVERSITY}/public/student/form/download?customStudentId=${info?.customStudentId}&instituteId=${info?.instituteId}`,
                      "_blank" // <- This is what makes it open in a new window.
                    )
                  }
                >
                  <u>Click Here</u>
                </span>{" "}
                to download your form.
              </p>
            </div>
          </>
        )}


      </Grid>
    </Grid>
  );
};

export default UniversityApplicationForm;
