import React, { useEffect, useState } from 'react';

import Footer from '../../Components/Footer';
import { Formik, Form, yupToFormErrors } from 'formik';
import { Checkbox, CircularProgress, Divider, List, ListItem, ListItemIcon, ListItemText, Snackbar, Step, StepButton, StepIcon, StepLabel, Stepper, TextField as Upload } from '@material-ui/core';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography
} from '@material-ui/core';
import Textfield from '../../Components/FormsUI/Textfield';
import Viewfield from '../../Components/FormsUI/Viewfield';
import SelectDistrict from '../../Components/FormsUI/SelectDistrict';
// import { DatePicker } from "@progress/kendo-react-dateinputs";
import Select from '../../Components/FormsUI/Select';
import DatePicker from '../../Components/FormsUI/DataTimePicker';
import { fatheroccupationdata, motheroccupationdata, autismData, bloodData, genederData, quotaData2, religionData, examNameData, gradeData, boardData, examGroupData, medium } from '../../data/data';
import { Link, useHistory } from "react-router-dom";
import useReactRouter from 'use-react-router';
import { Button as Submit } from '@material-ui/core';
import { Button as Buttonh } from '@material-ui/core';
import { green, purple } from "@material-ui/core/colors";
import { TextField as TextF, MenuItem } from '@material-ui/core';
import HeaderMenu from '../../Components/Header/headeradmission';
import AdmissionMenu from '../../Components/Header/largeadmissionmenu';



const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(8),
  },
  root: {
    color: green[900],
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)" // default
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C4C4C4" // customized
      }
    }
  }
}));





let otherInfo = JSON.parse(localStorage.getItem('otherInfo')) || {};
const LargeAdmissionForm = () => {
  const { match } = useReactRouter();
  const [instInfo, setinstInfo] = useState({});
  const [classData, setClassData] = useState([]);
  const [classDataId, setClassDataID] = useState(undefined);
  const [groupData, setgroupData] = useState([]);
  const [groupDataId, setgroupDataID] = useState(undefined);


  useEffect(() => {
    const fetchData = async () => {
      let res = await fetch(`${process.env.REACT_APP_API_ROOT}/public/online-applicantion/institute-info/${match.params.id}`)
      let data = await res.json()
      setinstInfo(data?.item)
      let classres = await fetch(`${process.env.REACT_APP_API_ROOT}/public/online/admission/class/list?instituteId=${match.params.id}`)
      let classdata = await classres.json()
      setClassData(classdata?.item)
    }
    fetchData();
  }, []);

  async function handleClassData(evt) {
    setClassDataID(evt.target.value)
    setgroupDataID(undefined);
    let groupres = await fetch(`${process.env.REACT_APP_API_ROOT}/public/online/admission/group/configuration/list/by/class-id?classId=${evt.target.value}&instituteId=${match.params.id}`)
    let groupdata = await groupres.json()
    setgroupData(groupdata?.item)
  }

  async function handleGroupData(evt) {
    setgroupDataID(evt.target.value)
    otherInfo = groupData?.find(item => item.id == evt.target.value)
  }

  const [info, setInfo] = useState({});

  useEffect(() => {
    let infox = JSON.parse(localStorage.getItem('studentinfo'))
    if (infox === null) {
      setInfo({})
    } else {
      setInfo(infox)
    }
  }, [])



  const history = useHistory();

  if (info === null) {
    history.push("/");
  }

  const INITIAL_FORM_STATE = {
    studentName: info?.studentName,
    fatherName: info?.fatherName,
    motherName: info?.motherName,

    // medium: info?.medium,
    fatherWorkPlace: info?.fatherWorkPlace,
    motherWorkPlace: info?.motherWorkPlace,
    fatherEducation: info?.fatherEducation,
    fatherDesignation: info?.fatherDesignation,
    motherEducation: info?.motherEducation,
    motherDesignation: info?.motherDesignation,


    localGuardianMobile: info?.localGuardianMobile,
    localGuardianEmail: info?.localGuardianEmail,
    dateOfBirth: info?.studentDOB,
    religion: info?.studentReligion,
    gender: info?.studentGender,
    lastInstituteName: info?.lastInstituteName,
    mobileNumber: info?.mobileNumber,
    bloodGroup: info?.bloodGroup,
    quota: '',
    birthcertificateNo: '',
    nationality: '',
    autism: '',
    fatherMobile: '',
    fatherOccupation: '',
    fatherIncome: '',
    fatherNid: '',

    motherMobile: '',
    motherNid: '',
    motherOccupation: '',
    motherIncome: '',
    roll:'',
    presentAddress: '',
    permanentAddress: '',
    permanentThanaId: '',
    localGuardianName: '',
    presentDistrict: '',
    presentThanaId: '',
    permanentDistrict: '',
  };

  const FORM_VALIDATION = Yup.object().shape({
    studentName: Yup.string()
      .required('Required'),
      
    fatherName: Yup.string()
      .required('Required'),
    religion: Yup.string()
      .required('Required'),
    gender: Yup.string()

      .required('Required'),
    motherName: Yup.string()
      .required('Required'),
    presentAddress: Yup.string().required('Required'),
    permanentAddress: Yup.string().required('Required'),
    birthcertificateNo: Yup.string().required('Required'),
    dateOfBirth: Yup.string()
    .required('Required').nullable(),

    localGuardianName: Yup.string()
    .required('Required'),
    
    localGuardianMobile: Yup.string()
    .required('Required'),
    

    // localGuardianMobile: Yup.string()
    // .required('Required'),

    // localGuardianName: Yup.string()
    // .required('Required'),

  });



  const classes = useStyles();

  const [payId, setpayId] = useState();
  const [successPay, setsuccessPay] = useState(false);
  const [step, setStep] = useState(1);
  const [sameAs, setSameAs] = useState(false);

  const [districtList, setdistrictList] = useState([]);
  const [thanaList, setPresentthanaList] = useState([]);
  const [permanentThanaList, setPermanentThanaList] = useState([]);
  const [presentThanaId, setPresentThanaId] = useState('');
  const [permanentThanaId, setPermanentThanaId] = useState('');
  const [presentDisId, setPresentDisId] = useState('');
  const [permanentDisId, setPermanentDISId] = useState('');

  const [imageFileContent, setimageFileContent] = useState();
  const [imageFileSave, setimageFileSave] = useState('');
  const [imageFileName, setimageFileName] = useState(undefined);

  

  function clearFileInput(ctrl) {
    try {
      ctrl.value = null;
    } catch (ex) { }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }
  const imageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file?.size > 600000) {
      // file.target.value = null;
      clearFileInput(document.getElementById("imgfile"));
      setimageFileSave(false);
      setimageFileContent('');
      setimageFileName(undefined);
      alert("Image size should be less than 600KB");
      return;
    };
    if (reader !== undefined && file !== undefined) {
      setimageFileSave(true);

      reader.onloadend = () => {
        setimageFileName(file.name)
        setimageFileContent(reader.result.split(',')[1])
      }
      reader.readAsDataURL(file);
    }
  };


  const validateOthers = () => {
    // console.log('sss')
  }


  useEffect(() => {
    async function fetchMyAPI() {
      let disReponse = await fetch('https://api.shebashikkha.com/location/info/district-list')
      disReponse = await disReponse.json()
      //  console.log(disReponse)
      setdistrictList(disReponse?.item)
    }

    async function fetchThana() {
      if (info.districtId != null) {
        let thanaReponse = await fetch(`https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${info.districtId}`)
        thanaReponse = await thanaReponse.json()
        setPresentthanaList(thanaReponse?.item)
      }
    }
    fetchMyAPI();
    fetchThana();

  }, []);

  async function handlePresentDistrict(evt) {
    setPresentDisId(evt.target.value)
    let thanaReponse = await fetch(`https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${evt.target.value}`)
    thanaReponse = await thanaReponse.json()
    setPresentthanaList(thanaReponse?.item)
  }

  async function handlePresentThana(evt) {
    setPresentThanaId(evt.target.value)
    console.log(evt.target.value)
  }
  async function permanenThandleThana(evt) {
    setPermanentThanaId(evt.target.value)
    // console.log(evt.target.value)
  }


  async function handlePermanentDistrict(evt) {
    setPermanentDISId(evt.target.value)
    let thanaReponse = await fetch(`https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${evt.target.value}`)
    thanaReponse = await thanaReponse.json()
    setPermanentThanaList(thanaReponse?.item)
  }
  async function handlePermanentDistrict2(evt, thana) {
    setPermanentDISId(evt)
    let thanaReponse = await fetch(`https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${evt}`)
    thanaReponse = await thanaReponse.json()
    setPermanentThanaList(thanaReponse?.item)
    if (thanaReponse?.item.length > 0) {
      setPermanentThanaId(thana);
    }

  }


  async function paymentPage() {
    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_API_ROOT}/public/get/online-admission-fees-payment/spg/session-id?registrationId=${payId}&instituteId=${parseInt(match.params.id)}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        // body: JSON.stringify(submit)
      });
      if (rawResponse.status === 201 || rawResponse.status === 200) {
        const content = await rawResponse.json();
        if (content?.messageType === 1) {
          window.open('https://api.shebashikkha.com/public/goto/spg-online-admission-payment/portal?sessiontoken=' + content?.item);
        } else {
          alert(content?.message);
        }

      } else {
        alert("Something went wrong");

      }

    }
    catch (err) {
      // not jumping in here.
      console.log(err)
    }

  }





  // console.log('info', info);

  async function nextData(values) {

    let postData = {
      "studentImageFileContent": imageFileContent === undefined ? "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" : imageFileContent,
      "studentImageName": imageFileName === undefined ? "no_image.png" : imageFileName,
      "studentImageFileSave": true,
      "academicYear": 2023,

      "applicantName": values.studentName,
      "roll": values.roll,

      
      "birthcertificateNo": values.birthcertificateNo,
      "dateOfBirth": values.dateOfBirth,
      "gender": values.gender,
      "religion": values.religion,
      "bloodGroup": values.bloodGroup,
      "nationality": values.nationality,
      "mobileNumber": values.mobileNumber,
      "fatherName": values.fatherName,



      "fatherNid": values.fatherNid,
      "fatherOccupation": values.fatherOccupation,
      "motherName": values.motherName,
      "quota": values.quota,

      "motherNid": values.motherNid,
      "motherOccupation": values.motherOccupation,

      "presentAddress": values.presentAddress,
      "localGuardianName": values.localGuardianName,
      "localGuardianMobile": values.localGuardianMobile,
      "localGuardianEmail": values.localGuardianEmail,
      "lastInstituteName": values.lastInstituteName,
      "instituteId": match.params.id,
      "groupConfigId": otherInfo?.id,
      "admissionCharge": otherInfo?.admissionCharge,
      "serviceCharge": otherInfo?.serviceCharge,
      "fatherEducation": values.fatherEducation,
      "fatherIncome": values.fatherIncome,
      "motherMobile": values.motherMobile,
       "fatherMobile": values.fatherMobile,
      "motherEducation": values.motherEducation,
      "motherIncome": values.motherIncome,
      "presentThanaId": presentThanaId,
      "permanentAddress": values.permanentAddress,
      "permanentThanaId": permanentThanaId,

      
      // other
    }
    //console.log(postData)
    finalSubmit(postData);
  };


  const [loading, setLoading] = React.useState(false);
  async function finalSubmit(data) {
    setLoading(true);
    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_API_ROOT}/public/online/admission/applicant-info/save`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (rawResponse.status === 201 || rawResponse.status === 200) {
        const content = await rawResponse.json();
        // console.log(content);
        setLoading(false);
        if (content.messageType === 1) {
          setsuccessPay(true);
          setStep(4);
          setpayId(content.item)
        }
      } else {
        alert("Something went wrong");
        setLoading(false);
      }

    }
    catch (err) {
      // not jumping in here.
      console.log(err)
    }

  }



  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderMenu details={{ instituteName: instInfo?.institutename, address: instInfo?.instituteaddress, image: instInfo?.imageLink }} />
      </Grid>

      <Grid item xs={12} style={{ background: "#fff", boxShadow: "rgb(216 216 216) 0px 0px 15px 0px", padding: "15px" }}>

        <Divider />
        <AdmissionMenu />
        {!successPay && step === 1 &&
          <div className={classes.formWrapper}>

            <Formik
              initialValues={{
                ...INITIAL_FORM_STATE
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={values => {
                nextData(values);
              }}
            >

              {(formikProps) => {
                const { values, handleChange } = formikProps;
                return (
                  <Form>

                    <Grid container spacing={2}>

                      <Grid item xs={12}>
                        <Typography style={{ fontSize: "24px", textAlign: "center", marginBottom: "10px", color: "#f68b1f", fontWeight: "500" }}>
                          Student's Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 0 }}>
                        <Typography>
                          Name of The Class
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextF value={classDataId} required select={true} variant="outlined" fullWidth label="Class Name" onChange={(e) => {
                          handleClassData(e);
                        }} >
                          {classData.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.id}>
                                {item.name}
                              </MenuItem>
                            )
                          })}
                        </TextF>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextF value={groupDataId} required select={true} variant="outlined" fullWidth label="Group/Trade Name" onChange={(e) => {
                          handleGroupData(e);
                        }} >
                          {groupData.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.id}>
                                {item.groupName}
                              </MenuItem>
                            )
                          })}
                        </TextF>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Viewfield
                          label="Application Fees"
                          name={otherInfo?.admissionCharge}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Viewfield
                          label="Service Charge"
                          name={otherInfo?.serviceCharge}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Viewfield
                          label="Total Charge"
                          name={(otherInfo?.totalCharge)}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 0 }}>
                        <Typography>
                          Basic Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="studentName"
                          label="Student's Name*"

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="gender"
                          label="Gender*"
                          options={genederData}

                        />
                      </Grid>

                    
                      <Grid item xs={12} sm={4}>
                        <Select
                          name="religion"
                          label="Religion*"
                          options={religionData}

                        />
                      </Grid>


                      
                      <Grid item xs={12} sm={4}>

                      <DatePicker views={['year', 'month', 'day']} 
                          name="dateOfBirth"
                          label="Date Of Birth*"

                        />
                      </Grid>



                      <Grid item xs={12} sm={4}>
                        <Textfield inputProps={{ maxLength: 20 }}
                          name="birthcertificateNo"
                          label="Birth Certificate No./Vaccine Card*"

                        />
                      </Grid>


                      <Grid item xs={12} sm={4}>
                        <Select
                          name="bloodGroup"
                          label="Blood Group"
                          options={bloodData}

                        />
                      </Grid>


                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="nationality"
                          label="Nationality"

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="quota"
                          label="Quota"
                          options={quotaData2}

                        />
                      </Grid>
                      

                      <Grid item xs={12} sm={4}>
                        <Upload required id="imgfile" style={{ width: "100%" }} classes={{ root: classes.root }} variant="outlined" label="Student Photo" focused={imageFileSave == '' && true} name="file" type="file" accept="image/*" onChange={(e) => {
                          imageUpload(e)

                        }} />
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 0 }}>
                        <Typography>
                          Father's Information
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="fatherName"
                          label="Father's Name*"

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield inputProps={{ maxLength: 11 }}
                          name="fatherMobile"
                          label="Father's Mobile"

                        />
                      </Grid>


                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="fatherNid" inputProps={{ maxLength: 20 }}
                          label="Father's NID/Passport No."

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="fatherEducation"
                          label="Father's Education"

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="fatherOccupation"
                          label="Father's Occupation"
                          options={fatheroccupationdata}

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="fatherIncome"
                          label="Father's Monthly Income"

                        />
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography>
                          Mother's Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="motherName"
                          label="Mother's Name*"

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield inputProps={{ maxLength: 11 }}
                          name="motherMobile"
                          label="Mother's Mobile"

                        />
                      </Grid>
                      
                      <Grid item xs={12} sm={4}>
                        <Textfield inputProps={{ maxLength: 20 }}
                          name="motherNid"
                          label="Mother's NID/Passport No."

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="motherEducation"
                          label="Mother's Education"

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="motherOccupation"
                          label="Mother's Occupation"
                          options={motheroccupationdata}

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="motherIncome"
                          label="Mother's Monthly Income"

                        />
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 0 }}>
                        <Typography>
                         Contact Address
                        </Typography>
                      </Grid>

                     
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography>
                          Present Address
                        </Typography>
                      </Grid>




                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="presentAddress"
                          label="Present Address*"
                        //
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextF value={presentDisId} required select={true} variant="outlined" fullWidth label="District" onChange={(e) => {
                          handlePresentDistrict(e);
                        }} >
                          {districtList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.districtId}>
                                {item.districtName}
                              </MenuItem>
                            )
                          })}
                        </TextF>
                      </Grid>


                      <Grid item xs={12} sm={4}>
                        <TextF value={presentThanaId} required select={true} variant="outlined" fullWidth label="Police Station" onChange={(e) => {
                          handlePresentThana(e);
                        }} >
                          {thanaList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.thanaId}>
                                {item.thanaName}
                              </MenuItem>
                            )
                          })}
                        </TextF>
                      </Grid>



                      <Grid item xs={12} style={{ marginTop: 15, display: "flex", alignContent: "center", alignItems: "center" }}>
                        <div>
                          <Typography>
                            Permanent Address
                          </Typography>
                        </div>
                        <div style={{ display: "flex", alignContent: "center", alignItems: "center", marginLeft: 25 }}>
                          <Checkbox
                            checked={sameAs}
                            disabled={presentThanaId == '' ? true : false}
                            onChange={() => {
                              setSameAs(!sameAs);
                              if (!sameAs === true) {
                                formikProps.setFieldValue("permanentAddress", values.presentAddress);
                                handlePermanentDistrict2(presentDisId, presentThanaId);

                              } else if (!sameAs === false) {
                                formikProps.setFieldValue("permanentAddress", '');
                                setPermanentDISId('');
                                setPermanentThanaId('');
                              }
                            }}
                          />
                          <Typography>
                            Same as the Present Address
                          </Typography>

                        </div>
                      </Grid>


                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="permanentAddress"
                          label="Permanent Address*"
                        //
                        />
                      </Grid>


                      <Grid item xs={12} sm={4}>
                        <TextF value={permanentDisId} required select={true} variant="outlined" fullWidth label="District" onChange={handlePermanentDistrict} >
                          {districtList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.districtId}>
                                {item.districtName}
                              </MenuItem>
                            )
                          })}
                        </TextF>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextF value={permanentThanaId} required select={true} variant="outlined" fullWidth label="Police Station" onChange={(e) => {
                          permanenThandleThana(e);
                        }} >
                          {permanentThanaList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.thanaId}>
                                {item.thanaName}
                              </MenuItem>
                            )
                          })}
                        </TextF>
                      </Grid>


                      <Grid item xs={12} style={{ marginTop: 0 }}>
                        <Typography>
                        Legal Guardian Info(This information use for communication purpose)
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="localGuardianName"
                          label="Guardian's Name*"

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield inputProps={{ maxLength: 11 }}
                          name="localGuardianMobile"
                          label="Guardian's Mobile Number*"

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="localGuardianEmail"
                          label="Guardian's Email"

                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Textfield
                          name="lastInstituteName"
                          label="Last/Previous Institute's Name"
                        />
                      </Grid>

                      <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <Submit type="submit" style={{ float: "right", backgroundColor:"#f68b1f" }} color='primary' variant="contained" onClick={() => validateOthers()} >
                         Submit
                        </Submit>

                      </Grid>
                    </Grid>

                  </Form>
                );
              }}
            </Formik>

          </div>
        }


        {successPay && step === 4 &&
          <>
            <p className='MuiTypography-root MuiTypography-h5' style={{ color: "Orange", marginTop: 40, textAlign: "center" }}>Applicant Info Successfully Saved.</p>
            <p className='MuiTypography-root MuiTypography-h6' style={{ marginBottom: 20, textAlign: "center" }}>Your Application ID is <strong>{payId}</strong>. You will need this Applicant ID to download Admit card After Complete your Payment</p>
            <p className='MuiTypography-root MuiTypography-h6' style={{ marginTop: 0, textAlign: "center" }}><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => window.open(
              `${process.env.REACT_APP_API_ROOT}/public/online/admission/large-confirmation/letter/download?instituteId=${match.params.id}&registrationId=${payId}`,
              '_blank' // <- This is what makes it open in a new window.
            )}><u>Click Here</u></span> to download your form.</p>
            <p className='MuiTypography-root MuiTypography-h6' style={{ marginTop: 0, textAlign: "center" }}><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => paymentPage()} // <- This is what makes it open in a new window.
            ><u>Click Here</u></span> to make payment</p>


          </>
        }

      </Grid>
    </Grid>
  );
};

export default LargeAdmissionForm;
