import React, { useEffect, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FileSaver from 'file-saver';
import {
  Container,
  Grid,
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import useReactRouter from 'use-react-router';
import { Button as Buttonh } from '@material-ui/core';
import { green } from "@material-ui/core/colors";
import { TextField } from '@material-ui/core';
import AppMenu from '../Components/Header/appmenu';

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  root: {
    color: green[900],
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)" // default
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C4C4C4" // customized
      }
    }
  }
}));



const DownloadForm = () => {
  const classes = useStyles();
  const [insDetails, setinsDetails] = useState();
  const { historyx, location, match } = useReactRouter();
  const info = JSON.parse(sessionStorage.getItem('info'));
  if (info === null) {
    history.push("/login");
  }
  const [textid, settextid] = useState();
  const [payId, setpayId] = useState();
  const history = useHistory();
  useEffect(() => {
    let id = match.params.id;

    async function fetchMyAPI() {
      let response = await fetch('https://shebashikkha.com/public/institute/list')
      response = await response.json()
      // console.log(response.item)
      let ins = response.item.filter(item => item.instiltuteId == parseInt(id))
      // console.log(ins?.[0]);
      setinsDetails(ins?.[0])
    }

    fetchMyAPI();

  }, []);



  function downloadPdf() {
    window.open(`${process.env.REACT_APP_API_ROOT}/public/confirmation/letter/download/for/college?applicantId=${textid}&instituteId=${info?.instituteId}`);
  }
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <Header details={{ instituteName: info?.instituteName, address: info?.instituteAddress, image:info?.instituteImage }} />
      </Grid>
      <div style={{ background: "#fff", boxShadow: "rgb(216 216 216) 0px 0px 15px 0px", padding: "15px", width: "100%" }}>
      <AppMenu />
      <Divider />
      <br />
      <Grid item xs={12}>
        <Typography style={{ fontSize: "24px", textAlign:"center", marginBottom: "10px", color: "#009688", fontWeight: "500" }}>
          Download
        </Typography>
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid item xs={12}>
        <Container maxWidth="md">
          <form onSubmit={downloadPdf}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField id="outlined-basic" label="Applicant Id" variant="outlined" fullWidth required type="number" onChange={(e) => settextid(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Buttonh type="submit" color="primary" variant="contained" fullWidth style={{ height: 55 }}> Download</Buttonh>
              </Grid>
            </Grid>
          </form>
        </Container>
       
      </Grid>
      </div>
    </Grid>
  );
};

export default DownloadForm;
