import React from 'react';
import { Toolbar, AppBar, Typography, Container, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { encode } from 'js-base64';

function Base64EncodeUrl(str) {
  return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
}

const AppMenuUniversity = (props) => {
  // console.log('hello', props)
  const history = useHistory();
  const { match } = useReactRouter();

  function goHome() {
    history.push("/university/login");
  }
  function viewApplication() {
    history.push("/university/online-applicant-info/" + parseInt(match.params.id));
  }
  function viewPayment() {
    const basestring = encode(`${props.details.customStudentId}-${props.details.instituteId}`)
    const encodedString = Base64EncodeUrl(basestring)
    window.open(
      `https://studentsheba.com/go/${encodedString}`,
      '_blank' // <- This is what makes it open in a new window.
    );
    // history.push("/online-applicant-fee-payment/" + parseInt(match.params.id));
  }

  function viewDownload() {
    window.open(
      `${process.env.REACT_APP_API_ROOT_UNIVERSITY}/public/student/form/download?customStudentId=${props.details.customStudentId}&instituteId=${props.details.instituteId}`,
      '_blank' // <- This is what makes it open in a new window.
    );
    //history.push("/online-applicant-confirmation-paper/");
  }

  return (
    <>
      <div style={{ display: "flex", marginBottom: "20px", marginTop: "20px", justifyContent: "center" }}>
        <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={() => goHome()}> Home</Button>
        <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={() => viewPayment()}> Payment</Button>
        {props.details.downloadFlag !== 0 && <Button variant="contained" color="primary" onClick={() => viewDownload()}> Download</Button>}
      </div>

    </>
  );
};

export default AppMenuUniversity;