import React from 'react';
import { Toolbar, AppBar, Typography, Grid, Container } from '@material-ui/core';
import background from './background.png'
const Footer = (props) => {
  // console.log('hello',props)
  return (
    <>
      <div className="footer" id="applicantInfoSection" style={{ backgroundImage:"url(" + background + ")", borderBottom: "5px solid #037651", backgroundRepeat:"no-repeat", backgroundSize:"cover" }}>
        <p style={{color: '#fff'}}>© All rights reserved. | Powered By <a style={{color: '#fff', textDecoration: 'underline'}} href="https://shebadigital.com/" rel="nofollow">Sheba Digital Limited | Part of Sheba Group</a>.</p>
      </div>
    </>
  );
};

export default Footer;